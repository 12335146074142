import {
    IonLabel,
    IonButton,
    useIonAlert,
    IonText, IonIcon, IonLoading, IonCard, IonCardContent, IonCol, IonRow
} from '@ionic/react';
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../context/state";
import {profileModel} from "../models/Data";
import {cloudUploadOutline, fileTrayFullOutline} from "ionicons/icons";
import TmpImage from '../assets/bi_image-alt.png'
import {useHistory} from "react-router-dom";
import Resizer from "react-image-file-resizer";

interface Props {
    step: any;
    setStep: Function;
    required: boolean;
}

export default function StoreRegisterStep3(props: Props) {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [fileUploadImage, setFileUploadImage] = useState<Array<File>>([])
    const [fileUploadDocument, setFileUploadDocument] = useState<Array<File>>([])
    const [storeProfile, setStoreProfile] = useState<profileModel>(new profileModel({}));
    const getS3Url = useCallback( async (fileKey) => {
        return await appContext.apiService.getS3Url(fileKey);
    }, []);

    const [imageFile, setImageFile] = useState<Array<string>>([]);
    const [publicImageUrl, setImagePublicUrl] = useState<Array<string>>([]);
    const [documentFile, setDocumentFile] = useState<Array<string>>([]);
    const [publicDocUrl, setDocPublicUrl] = useState<Array<string>>([]);

    const [showLoading, setShowLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const history = useHistory();

    useEffect(() => {
        console.log('storeProfile', appContext.registerStore);
        if (appContext.registerStore) {
            setStoreProfile(appContext.registerStore);
            if(appContext.registerStore.fileUpload?.fileImage){
                const image = appContext.registerStore.fileUpload.fileImage.split(',');
                setImageFile(image);
            }

            if(appContext.registerStore.fileUpload?.fileDocument){
                const image = appContext.registerStore.fileUpload.fileDocument.split(',');
                const temp = [];
                for (const item of image){
                    temp.push(item)
                }
                setDocumentFile(temp);
            }
        }

        if(appContext.registerStore?.status == 'approved' || appContext.registerStore?.status == 'editRequest'){
            setDisableBtn(false);
        }
    }, [appContext.setRegisterStore]);

    useEffect(() => {
        const getArrFunc  = async () => {
            const arr:Array<string> = [];
            for(let i of imageFile){
                console.log(i);
                if(i.indexOf('blob') <= -1){
                    const url = await getS3Url(i);
                    arr.push(url);
                }else{
                    arr.push(i);
                }
            }
            return arr;
        }
        getArrFunc().then( arr => {
            setImagePublicUrl(arr);
        })
    }, [imageFile]);

    useEffect(() => {
        const getArrFunc  = async () => {
            const arr:Array<string> = [];
            for(let i of documentFile){
                console.log(i);
                if(i.indexOf('blob') <= -1){
                    const url = await getS3Url(i);
                    arr.push(url);
                }else{
                    arr.push(i);
                }
            }
            return arr;
        }
        getArrFunc().then( arr => {
            setDocPublicUrl(arr);
        })
    }, [documentFile]);


    useEffect(() => {
        if((fileUploadImage.length > 0 && fileUploadDocument.length > 0) || (appContext.registerStore.status)){
            setDisableBtn(false);
        }
    },[fileUploadImage, fileUploadDocument])
    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            return Resizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    async function registerStore() {
        if (!fileUploadImage && appContext.registerStore.status != 'approved') {
            if(appContext.registerStore?.status != 'editRequest'){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'กรุณาอัพโหลดรูปภาพหน้าร้าน',
                    buttons: ['Ok',],
                }).catch()
                return false;
            }
        }
        if (!fileUploadDocument && appContext.registerStore.status != 'approved') {
            if(appContext.registerStore?.status != 'editRequest'){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'กรุณาอัพโหลดเอกสารยืนยันตนร้าน',
                    buttons: ['Ok',],
                }).catch()
                return false;
            }
        }

        const store = storeProfile;
        const data = new FormData();
        //set formData
        if (fileUploadImage) {
            console.log('fileUploadImage', fileUploadImage);
            for (const item of fileUploadImage) {
                let fImage = await resizeFile(item);
                // @ts-ignore
                data.append('fileImage', fImage);
            }
        }
        if (fileUploadDocument) {
            console.log('fileUploadDocument', fileUploadDocument);
            for (const item of fileUploadDocument) {
                const fImage = await resizeFile(item);
                // @ts-ignore
                data.append('fileDocument', fImage);
            }
        }
        // console.log('store', store)
        for(const item in store){
            // @ts-ignore
            if(store[item]){
                // @ts-ignore
                data.append(item, store[item])
            }
        }
        setShowLoading(true);
        await appContext.apiService.registerBusinesses(data).then((response:any) => {
            if (response.success) {
                if(appContext.userStatus == 'waitApprove'){
                    present({
                        cssClass: 'ionic-alert',
                        header: 'สมัครร้านค้า',
                        message: 'บันทึกข้อมูลสำเร็จ <div>รอเจ้าหน้าที่ยืนยันข้อมูลร้านค้า</div>',
                        buttons: ['Ok',],
                    }).catch()

                    history.push('/register');
                }else{
                    present({
                        cssClass: 'ionic-alert',
                        header: 'ส่งแก้ไขข้อมูลสำเร็จ',
                        message: 'บันทึกข้อมูลสำเร็จ <div>รอเจ้าหน้าที่ยืนยัน การขอแก้ไขข้อมูล</div>',
                        buttons: ['Ok',],
                    }).catch()

                    history.push('/profile');
                }
            }
            setShowLoading(false);
        });
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();

            registerStore().catch()

        }} id={"register-store-form"} style={{marginTop: '-30px'}}>
            <IonCard>
                <IonCardContent>
                    <div style={{textAlign: 'center'}}>
                        <IonLabel> 1.อัพโหลดรูปภาพหน้าร้านของท่าน และ ตัวอย่างสินค้าในร้าน</IonLabel>
                        <IonLabel> </IonLabel>
                    </div>
                    <div style={{width: '100%', border: " 1px dashed rgba(0, 0, 0, 0.36) ", textAlign: 'center'}}>
                        {fileUploadImage?.length == 0 && imageFile.length == 0 ? (
                            <div>
                                <IonRow>
                                    <IonCol size={"12"}>
                                        <img src={TmpImage} style={{width: 100, margin: 15}}/>
                                    </IonCol>
                                </IonRow>
                            </div>
                        ) :
                            <div style={{ margin: 30}}>
                                <IonRow>
                                    {publicImageUrl.length > 1 ? publicImageUrl.map(r => (
                                            <IonCol size={"6"}>
                                                <img src={r} style={{width: 100, margin: 15}}/>
                                            </IonCol>)
                                    ) : (
                                        <IonCol size={"12"}>
                                            <img src={publicImageUrl[0]} style={{width: 100, margin: 15}}/>
                                        </IonCol>
                                    )}
                                </IonRow>
                            </div>
                        }

                        {
                            props.required && (
                                <div>
                                    <IonButton size={"small"} className={"kgo-btn"} style={{color : "#FFFFFF"}}>
                                        <IonIcon icon={cloudUploadOutline} slot="start"/>
                                        <input id="file" hidden type="file" multiple={true} onChange={(e) => {
                                            if (e.target.files) {
                                                // console.log('e.target.files', e.target.files);
                                                const files = e.target.files;
                                                const temp = [];
                                                const filePath = [];
                                                for (const i in files) {
                                                    if (!isNaN(parseInt(i))) {
                                                        temp.push(files[i])
                                                        filePath.push(URL.createObjectURL(files[i]))
                                                    }
                                                    // if (files[i].size > 5e6) {
                                                    //     // @ts-ignore
                                                    //     e.target.value = null;
                                                    //
                                                    //     present({
                                                    //         cssClass: 'ionic-alert',
                                                    //         header: 'แจ้งเตือน',
                                                    //         message: 'พบขนาดไฟล์ห้ามเกิน 5MB',
                                                    //         buttons: ['Ok',],
                                                    //     }).catch()
                                                    // } else if (!isNaN(parseInt(i))) {
                                                    //     temp.push(files[i])
                                                    //     filePath.push(URL.createObjectURL(files[i]))
                                                    // }
                                                }

                                                setFileUploadImage(temp);
                                                setImageFile(filePath);
                                                console.log('temp', temp.length)
                                            }
                                        }}/>
                                        <label htmlFor="file"> อัพโหลดรูปภาพ</label>
                                    </IonButton>
                                </div>
                            )
                        }

                    </div>
                    <div style={{textAlign: 'center', marginTop: 15}}>
                        <IonLabel>2. อัพโหลดเอกสารยืนยันตนร้านของท่าน </IonLabel>
                        <IonLabel>เช่น ทะเบียนพานิชย์ หรือ สำเนาบัตรประชาชนเพื่อใช้ในการยืนยันตัวตน</IonLabel>
                    </div>
                    <div style={{
                        width: '100%',
                        border: " 1px dashed rgba(0, 0, 0, 0.36) ",
                        textAlign: 'center',
                        marginTop: 5
                    }}>
                        {fileUploadDocument?.length == 0 && documentFile.length == 0 ? (
                                <div>
                                    <IonRow>
                                        <IonCol size={"12"}>
                                            <img src={TmpImage} style={{width: 100, margin: 15}}/>
                                        </IonCol>
                                    </IonRow>
                                </div>
                            ) :
                            <div style={{ margin: 30}}>
                                <IonRow>
                                    {publicDocUrl.length > 1 ? publicDocUrl.map(r => (
                                        <IonCol size={"6"}>
                                            <img src={r} style={{width: 100, margin: 15}}/>
                                        </IonCol>)
                                    ) : (
                                        <IonCol size={"12"}>
                                            <img src={publicDocUrl[0]} style={{width: 100, margin: 15}}/>
                                        </IonCol>
                                    )}
                                </IonRow>
                            </div>
                        }
                        {
                            props.required && (
                                <div>
                                    <IonButton size={"small"} className={"kgo-btn"} style={{color : "#FFFFFF"}}>
                                        <IonIcon icon={cloudUploadOutline} slot="start"/>
                                        <input id="file2" hidden type="file" multiple={true} onChange={(e) => {
                                            if (e.target.files) {
                                                // console.log('e.target.files', e.target.files);
                                                const files = e.target.files;
                                                const temp = [];
                                                const filePath = [];
                                                for (const i in files) {
                                                    if (!isNaN(parseInt(i))) {
                                                        temp.push(files[i])
                                                        filePath.push(URL.createObjectURL(files[i]))
                                                    }
                                                    // if (files[i].size > 5e6) {
                                                    //     // @ts-ignore
                                                    //     e.target.value = null;
                                                    //
                                                    //     present({
                                                    //         cssClass: 'ionic-alert',
                                                    //         header: 'แจ้งเตือน',
                                                    //         message: 'พบขนาดไฟล์ห้ามเกิน 5MB',
                                                    //         buttons: ['Ok',],
                                                    //     }).catch()
                                                    // } else if (!isNaN(parseInt(i))) {
                                                    //     temp.push(files[i]);
                                                    //     filePath.push(URL.createObjectURL(files[i]));
                                                    // }
                                                }

                                                setFileUploadDocument(temp);
                                                setDocumentFile(filePath);
                                                console.log('temp', temp.length)

                                            }
                                        }}/>
                                        <label htmlFor="file2"> อัพโหลดรูปภาพ</label>
                                    </IonButton>

                                </div>
                            )
                        }
                    </div>
                </IonCardContent>
            </IonCard>
            <IonRow className={"kgo-row ion-text-center kgo-m"}>
                <IonCol>
                    {
                        props.required ? (
                            <IonButton className={"kgo-btn"} expand={"block"} type={"submit"} disabled={disableBtn}>ส่ง</IonButton>
                        ) : (
                            <IonButton className={"kgo-btn"} expand={"block"} type={"button"} routerLink={"/profile"}>กลับไปหน้า Profile</IonButton>
                        )
                    }

                </IonCol>
            </IonRow>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'}
            />
        </form>
    );
};


import {
    IonButton, IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonList, IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    useIonAlert,
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../context/state";
import {profileModel} from "../models/Data";

interface Props {
    step: any;
    setStep: Function;
    required: boolean;
}

export default function StoreRegisterStep1(props: Props) {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [otherType, setOtherType] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [email, setEmail] = useState('')
    const [referral, setReferral] = useState("")
    const [storePhoneNumber, setStorePhoneNumber] = useState('')
    const [storeProfile, setStoreProfile] = useState<profileModel>(new profileModel({}));

    const [disableType, setDisableType] = useState(true)
    const [disableBtn, setDisableBtn] = useState(true)

    useEffect(() => {
        console.log('appContext.registerStore?.businessName', appContext.registerStore?.businessName)
        if (appContext.registerStore?.businessName) {
            setName(appContext.registerStore?.businessName);
            setStorePhoneNumber(appContext.registerStore?.businessPhoneNumber ?? '');
            setOwnerName(appContext.registerStore?.ownerName ?? '');
            setEmail(appContext.registerStore?.businessEmail ?? '');
            setType(appContext.registerStore?.businessType);
            setOtherType(appContext.registerStore?.businessOtherType);

            setStoreProfile(appContext.registerStore);
        } else {
            if (props.required) {
                appContext.apiService.getBizRegister().then((response: any) => {
                    console.log('response', response);
                    if (response.success) {
                        const nProfile = new profileModel(response.data);
                        appContext.setRegisterStore(nProfile);
                    }
                }).catch((e) => {
                    console.log(e);
                });
            } else {
                appContext.apiService.getBalance().then((response: any) => {
                    if (response?.success) {
                        const nProfile = new profileModel(response.profile);
                        console.log(response.profile, nProfile);
                        appContext.setRegisterStore(nProfile);
                    }
                });
            }
        }

        if (appContext.registerStore?.businessType == 'อื่นๆ') {
            setDisableType(false);
        }
    }, [appContext.registerStore]);

    async function registerStore() {
        let store = storeProfile;
        store.businessName = name;
        store.businessType = type;
        store.businessOtherType = otherType;
        store.ownerName = ownerName;
        store.businessEmail = email;
        store.businessPhoneNumber = storePhoneNumber;
        store.referral = referral;
        appContext.setRegisterStore(store)
        props.setStep(1);
    }

    useEffect(() => {
        if ((name && type && ownerName && storePhoneNumber) || !props.required) {
            setDisableBtn(false);
        }

        //referral
        if (appContext.registerStore?.status != 'approved' && appContext.registerStore?.status != 'editRequest') {
            const refCode = localStorage.getItem("referral");
            if (refCode) {
                setReferral(refCode);
            }
        }
    }, [name, type, ownerName, storePhoneNumber]);

    return (
        <IonList>

            <form onSubmit={(e) => {
                e.preventDefault();
                if (!type) {
                    present({
                        cssClass: 'ionic-alert',
                        header: 'แจ้งเตือน',
                        message: 'โปรดเลือกประเภทร้านค้า',
                        buttons: ['Ok',],
                    })
                    return;
                }
                if (type == 'อื่นๆ' && !otherType) {
                    present({
                        cssClass: 'ionic-alert',
                        header: 'แจ้งเตือน',
                        message: 'โปรดระบุประเภทร้านค้า',
                        buttons: ['Ok',],
                    })
                    return;
                }
                registerStore().catch()
            }} id={"register-store-form"}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked" className={"label-form"}>ชื่อร้านค้า <IonText
                            color={"danger"}>*</IonText></IonLabel>
                        <IonInput required={props.required} type={"text"} value={name} placeholder="ระบุชื่อร้านค้า"
                                  onIonChange={e => {
                                      setName(e.detail.value!);
                                  }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" className={"label-form"}>ประเภทร้านค้า <IonText
                            color={"danger"}>*</IonText></IonLabel>
                        <select value={type} placeholder='เลือกประเภทร้านค้า' className={'select-type'}
                                   onChange={e => {
                                       if (e.target.value == 'อื่นๆ') {
                                           setDisableType(false);
                                       } else {
                                           setDisableType(true);
                                       }
                                       setType(e.target.value)
                                       console.log('e.target.value', e.target.value);
                                   }} >
                            <option value="" disabled selected>เลือกประเภทร้านค้า</option>
                            <option value="อาหารและเครื่องดื่ม">อาหารและเครื่องดื่ม</option>
                            <option value="ไลฟ์สไตล์ ของตกแต่งบ้าน">ไลฟ์สไตล์ ของตกแต่งบ้าน</option>
                            <option value="เสื้อผ้า แฟชั่น เครื่องประดับ">เสื้อผ้า แฟชั่น
                                เครื่องประดับ</option>
                            <option value="โรงแรม ที่พัก">โรงแรม ที่พัก</option>
                            <option value="ท่องเที่ยวและกีฬา">ท่องเที่ยวและกีฬา</option>
                            <option value="งานบริการ">งานบริการ</option>
                            <option value="อื่นๆ">อื่นๆ</option>
                        </select>
                    </IonItem>
                    <IonItem hidden={disableType} style={{paddingLeft: 22}}>
                        <IonLabel position="stacked" className={"label-form"}>โปรดระบุประเภทร้านค้าอื่นๆ <IonText
                            color={"danger"}>*</IonText></IonLabel>
                        <IonInput type={"text"} hidden={disableType}
                                  value={otherType} placeholder="โปรดระบุ" onIonChange={e => {
                            setOtherType(e.detail.value!);
                        }}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" className={"label-form"}>ชื่อเจ้าของร้าน <IonText
                            color={"danger"}>*</IonText></IonLabel>
                        <IonInput required={props.required} type={"text"}
                                  value={ownerName} placeholder="ระบุชื่อเจ้าของร้าน" onIonChange={e => {
                            setOwnerName(e.detail.value!);
                        }}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" className={"label-form"}>เบอร์โทรศัพท์ร้านค้า <IonText
                            color={"danger"}>*</IonText></IonLabel>
                        <IonInput required={props.required} autocomplete={"tel"} type={"number"} maxlength={10}
                                  value={storePhoneNumber} placeholder="ระบุเบอร์โทรศัพท์" onIonChange={e => {
                            setStorePhoneNumber(e.detail.value!);
                        }}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked" className={"label-form"}>Email </IonLabel>
                        <IonInput autocomplete={"email"} className={"label-form"} type={"text"}
                                  value={email} placeholder="ระบุ Email" onIonChange={e => {
                            setEmail(e.detail.value!);
                        }}/>
                    </IonItem>
                    {
                        (appContext.registerStore?.status != 'approved' && appContext.registerStore?.status != 'editRequest') ? (
                                <IonItem>
                                    <IonLabel position="stacked" className={"label-form"}>เบอร์โทรผู้แนะนำ </IonLabel>
                                    <IonInput autocomplete={"tel"} className={"label-form"} type={"text"} maxlength={10}
                                              value={referral} placeholder="ระบุ เบอร์โทรผู้แนะนำ" onIonChange={e => {
                                        setReferral(e.detail.value!);
                                        if (e.detail.value && e.detail.value.length == 10) {
                                            localStorage.setItem('referral', e.detail.value);
                                        }
                                    }}/>
                                </IonItem>)
                        : null

                    }
                </IonList>

                <IonRow className={"kgo-row ion-text-center kgo-m"}>
                    <IonCol>
                        <IonButton className={"kgo-btn"} expand={"block"} type={"submit"}
                                   disabled={disableBtn}>ถัดไป</IonButton>
                    </IonCol>
                </IonRow>
            </form>
        </IonList>
    );
};


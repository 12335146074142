import {
    IonButton, IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonIcon, IonLoading, IonCard, IonCardContent, IonText, IonAlert, useIonAlert
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import {
    copyOutline,
    person,
    informationCircleOutline, arrowBack, cart, logoFacebook, alert, alertCircle
} from "ionicons/icons";
import {profileModel} from "../models/Data";
import {RouteComponentProps} from "react-router";
import {DateTime} from "luxon";

const StoreProfile: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [showLoading, setShowLoading] = useState(false);
    const [dataProfile, setDataProfile] = useState<profileModel>(new profileModel({}));
    const [profileImage, setProfileImage] = useState(process.env.PUBLIC_URL + '/assets/icon/user.png');
    const [countReferral, setCountReferral] = useState(0);

    useEffect(() => {
        appContext.apiService.getBizRegister().then((response: any) => {
            console.log('getBizRegister', response);
            if (response) {
                const nProfile = new profileModel(response.data);
                appContext.setRegisterStore(nProfile);
            }
        }).catch((e) => {
            console.log(e);
        });

        if (appContext.profile?.businessName) {
            console.log('appContext.profile',appContext.profile);
            setDataProfile(appContext.profile);
            if (appContext.profile.profileImage) {
                setProfileImage(appContext.profile.profileImage);
            }
        } else {
            console.log('getProfile')
            appContext.getProfile().then((res: any) => {
            });
        }
        getReferral();
    }, [appContext?.profile]);

    function logOut(){
        present({
            cssClass: 'ionic-alert',
            header: 'ออกจากระบบ',
            message: 'ยืนยันการออกจากระบบ ?',
            buttons: [
                { text: 'Ok', handler: (d) => appContext.apiService.signOut() },
                { text: 'Cancel', handler: (d) => {} },
            ],
        }).catch()
    }

    const getReferral = () => {
        appContext.apiService.getCountReferral().then((response: any) => {
            // console.log('getCountReferral', response);
            setCountReferral(response.data);
        }).catch((e) => {
            console.log(e);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonButtons slot="start">
                            <IonIcon icon={arrowBack} size="large"  onClick={e => {
                                history.push('/main');
                            }} />
                        </IonButtons>
                        <IonTitle>Profile</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-color"}>
                                <IonCardContent>
                                    <IonRow style={{margin: "6px 0"}}>
                                        <IonCol size={"3"} className={"ion-text-center"}>
                                            {/*<IonIcon icon={personCircle} style={{fontSize:"45px",color:"#FFFFFF"}}></IonIcon>*/}
                                            <img src={profileImage} style={{width: 80, borderRadius: "50%"}}/>
                                        </IonCol>
                                        <IonCol size={"9"} style={{color: "#FFFFFF", fontSize: 16}}>
                                            <IonText style={{display: "grid",marginLeft: 15}}>
                                                <IonText>
                                                {dataProfile.businessName ? dataProfile?.businessName : '-'}
                                                </IonText>
                                                <IonText >
                                                    {dataProfile.businessPhoneNumber}
                                                </IonText>
                                            </IonText>
                                        </IonCol>

                                        <IonCol size={"12"} style={{fontSize: 16, fontWeight: 400,color: "#000000",}}>
                                            Wallet address chain
                                        </IonCol>
                                        <IonCol size={"12"}>
                                            <IonText style={{fontSize: 12, fontWeight: 400,color: "#FFFFFF",}}>
                                                {(appContext.address) ? appContext.address.substr(0, 15) + '...' + appContext.address.substr(-15) : ''}
                                                <IonText onClick={() => navigator.clipboard.writeText(appContext.address)}
                                                         style={{
                                                             color: "#FFFFFF",
                                                             fontSize: 9,
                                                             background: "#6D6C6C",
                                                             borderRadius: 24,
                                                             padding: "6px 10px",
                                                             marginLeft: 10,
                                                             cursor: "pointer",
                                                             float:'right'
                                                         }}>
                                                    <IonIcon icon={copyOutline}/> คัดลอก
                                                </IonText>
                                            </IonText>
                                        </IonCol>
                                        <IonCol size={"12"}>
                                            <IonText style={{fontSize: 12, fontWeight: 400,color: "#000000",}}>
                                                จำนวนคนที่แนะนำ <IonText style={{color:'white'}}>{countReferral}</IonText> คน
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            {
                                appContext?.registerStore?.status == 'editRequest' ?
                                    (
                                        <IonCard className={"kgo-card"} style={{background: '#fff'}}>
                                            <IonCardContent>
                                                <IonText color={'primary'}>
                                                    <h2>
                                                        คำขอแก้ไข อยู่ระหว่างตรวจสอบ

                                                    </h2>
                                                </IonText>
                                                <IonText style={{color:'black',fontSize:14}}>
                                                    แก้ไขเมื่อ { DateTime.fromMillis(appContext?.registerStore?.updatedAt).setLocale("th").toFormat('d MMM yyyy') }
                                                </IonText>
                                            </IonCardContent>
                                        </IonCard>
                                    ): null
                            }
                            {
                                appContext?.registerStore?.status == 'reject' ?
                                    (
                                        <IonCard className={"kgo-card"} style={{background: '#fff'}}>
                                            <IonCardContent>
                                                <IonText>
                                                    <h2 style={{color:'#FC3131',fontSize:16}}>
                                                        <IonIcon icon={alertCircle} /> คำขอแก้ไขถูกปฎิเสธ
                                                    </h2>
                                                </IonText>
                                                <IonText style={{fontSize:18,color:"#242424",fontWeight:400}}>
                                                    <h2 style={{marginTop:12,marginLeft:22}}>
                                                        เนื่องจาก:{ appContext?.registerStore?.rejectDetail }
                                                    </h2>
                                                </IonText>
                                                <IonText >
                                                    <p style={{color:'#555555',fontSize:13,textAlign:'center'}}>
                                                        ท่านสามารถส่งแก้ไขคำร้องได้ที่เมนู<br/>
                                                        ยืนยันตัวตนกับ InfinitLand
                                                    </p>
                                                </IonText>
                                            </IonCardContent>

                                        </IonCard>
                                    ): null
                            }
                        </IonCol>
                    </IonRow>

                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card"} style={{background:'#FD9E18'}}>
                                <IonCardContent>
                                    <IonRow>

                                        {/* <IonCol size={"12"} style={{textAlign: "left"}}>
                                            <IonButton onClick={(e: any) => {
                                                // setIsOpenModalEditProfile(true);
                                                history.push('/register-view')
                                            }} color={"light"} expand={"block"}>
                                                <span className="ion-text-left">
                                                    <IonIcon icon={person}/> ข้อมูลร้านค้า
                                                </span>
                                            </IonButton>
                                        </IonCol> */}

                                        <IonCol size={"12"} style={{textAlign: "left"}}>
                                            <IonButton onClick={(e: any) => {
                                                // setIsOpenModalEditProfile(true);
                                                history.push('/register-form')
                                            }} color={"light"} expand={"block"}>
                                                <span className="ion-text-left" >
                                                    <IonIcon icon={person}/> ยืนยันตัวตันกับ InfinitLand
                                                </span>
                                            </IonButton>

                                        </IonCol>
                                        <IonCol size={"12"} style={{textAlign: "left"}}>
                                            <IonButton onClick={(e: any) => {
                                                // setIsOpenModalEditProfile(true);
                                                history.push('/listing-profile')
                                            }} color={"light"} expand={"block"}>
                                                <span className="ion-text-left">
                                                    <IonIcon icon={cart}/> ข้อมูลร้านค้า
                                                </span>
                                            </IonButton>
                                        </IonCol>
                                        {/*<IonCol size={"12"} style={{textAlign: "left"}}>*/}
                                        {/*    <IonButton onClick={(e: any) => {*/}

                                        {/*    }} color={"light"} expand={"block"}>*/}
                                        {/*        <span className="ion-text-left"><IonIcon icon={callOutline}/> แก้ไขหมายเลขโทรศัพท์</span>*/}
                                        {/*    </IonButton>*/}
                                        {/*</IonCol>*/}

                                        {/*<IonCol size={"12"} style={{textAlign: "left"}}>*/}
                                        {/*    <IonButton onClick={(e: any) => {*/}

                                        {/*    }} color={"light"} expand={"block"}>*/}
                                        {/*        <span className="ion-text-left"><IonIcon icon={mailOutline}/> เปลี่ยนอีเมล์</span>*/}
                                        {/*    </IonButton>*/}
                                        {/*</IonCol>*/}

                                        <IonCol size={"12"} style={{textAlign: "left"}}>
                                            <IonButton routerLink={"/info"} color={"light"} expand={"block"}>
                                                <span className="ion-text-left"><IonIcon
                                                    icon={alertCircle}/> ติดต่อเรา</span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className={"kgo-row"} style={{marginTop: 32}}>
                        <IonCol size={"12"}>
                            <IonButton expand="block" className={'logoutButton'}  onClick={(e) => {
                                logOut();
                            }}>ออกจากระบบ
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </div>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Loading...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default StoreProfile;

import {
    IonButton,
    IonContent, IonInput, IonLabel,
    IonList,
    IonPage,
    IonGrid, IonProgressBar, useIonAlert, IonCol, IonText, IonRow, IonIcon
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router";
import {arrowForwardOutline} from 'ionicons/icons';

const MobileVerify: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const inputRef = useRef<any>(null);
    const [tel, setTel] = useState("")
    const [runningProcess, setRunningProcess] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const [present] = useIonAlert();
    useEffect(() => {
        if (inputRef?.current) {
            setTimeout(() => inputRef.current.setFocus(), 100);
        }
    }, [])

    async function sendOTP() {
        console.log('sendOTP');
        setRunningProcess(true);
        setDisableBtn(true);
        if (appContext.apiService) {
            await appContext.apiService.getOtp(tel).then((response: any) => {
                console.log('response', response);
                if (response?.success) {
                    appContext.setOtpRefData(response?.data);
                    appContext.setPhoneNumber(tel);

                    history.push('/otp-verify');
                } else {
                    present({
                        cssClass: 'ionic-alert',
                        header: 'Alert',
                        message: response?.message,
                        buttons: ['Ok',],
                    })
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                setRunningProcess(false);
                setDisableBtn(false);
            });
        } else {
            console.log('false');
        }
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className={"app-container"}>
                    <IonGrid>
                        <IonRow className="ion-text-center">
                            <IonCol size={"12"}>
                                <img src={process.env.PUBLIC_URL + '/assets/icon/KGO-Logo.png'}
                                     style={{maxHeight: 230, maxWidth: 94, marginTop: 100}}/>
                            </IonCol>

                            <IonCol size={"12"} style={{marginTop: 27, fontSize: 18, lineHeight: "27px"}}>
                                <IonText>
                                    เชื่อม โลกเสมือน กับ โลกความจริง
                                    <div>
                                        เข้าด้วยกันด้วยเหรียญ KGO
                                    </div>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center" style={{marginTop: 28}}>
                            <IonCol sizeMd={"6"} offsetMd={"3"} sizeXs={"10"} offsetXs={"1"}>
                                <IonLabel position="stacked" style={{marginBottom:'10px',textAlign:'center'}}>กรอกเบอร์โทรศัพท์ของคุณ </IonLabel>
                                <IonInput ref={(ref) => inputRef.current = ref} type="tel"
                                          placeholder="08xxxxxxxx"
                                          style={{fontSize: '1em'}}
                                          className={"kgo-input-light"}
                                          value={tel} maxlength={10} onIonChange={e => {
                                    setTel(e.detail.value!);
                                    if (e.detail.value!.length < 10) {
                                        setDisableBtn(true);
                                    } else {
                                        setDisableBtn(false);
                                    }
                                }}/>
                                <IonRow>
                                    <IonCol style={{padding: "28px 0"}}>
                                        <IonButton className={"kgo-btn"} expand={"block"} strong={true} disabled={disableBtn}
                                                   onClick={(e: any) => {
                                                       sendOTP().catch(console.error);
                                                   }}>
                                            ถัดไป <IonIcon slot="end" icon={arrowForwardOutline}/>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {runningProcess ? <IonProgressBar type="indeterminate"/> : null}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MobileVerify;

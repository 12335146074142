import {
    IonBackButton, IonButton,
    IonButtons, IonCard, IonCardContent, IonCheckbox,
    IonCol,
    IonContent,
    IonHeader, IonLabel, IonLoading,
    IonPage,
    IonRow,
    IonTitle, IonText,
    IonToolbar, useIonAlert, IonAlert, IonItem, IonInput, IonItemDivider
} from '@ionic/react';
import React, {useContext, useEffect, useRef, useState} from "react";
import {arrowBackOutline} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import {AppContext} from "../context/state";
import kgoNFT from "../assets/kgo-nft.png";
import keyImg from "../assets/key.png";
import dollar from "../assets/coin_kgo1.svg";

const Redemption: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);

    const [showAmount, setShowAmount] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(0);
    const [selectedTemplateText, setSelectedTemplateText] = useState('');
    const [amount, setAmount] = useState<number>(1);
    const [useKgo, setUseKgo] = useState('');
    const [price, setPrice] = useState(0);
    const [claimNow, setClaimNow] = useState<boolean>(true);
    const [listNft, setListNft] = useState([]);

    useEffect(() => {
        if (appContext.apiService) {
            getListNft().then();
        }
    }, [appContext.apiService]);

    const buyNft = async (amount: number, claimNow: boolean) => {
        appContext.apiService.buyNft(selectedTemplateId, amount, claimNow).then((res) => {
            setAmount(1);
            setShowAmount(false);
            if (res.success) {
                present({
                    cssClass: 'ionic-alert',
                    header: 'ทำรายการสำเร็จ',
                    message: 'สามารถตรวจสอบได้ที่แถบ "แลกของรางวัล"',
                    buttons: ['Ok',],
                }).then().catch(e => console.error(e.message))
            } else {
                present({
                    cssClass: 'ionic-alert',
                    header: '!! ทำรายการล้มเหลว',
                    message: res.message,
                    buttons: ['Ok',],
                }).then().catch(e => console.error(e.message))
            }
        }).finally(() => {
            setShowLoading(false);
        })
    }

    const getListNft = async () => {
        appContext.apiService.getListNft().then((response: any) => {
            if (response) {
                console.log('response?.data', response);
                const data =response?.data;
                setListNft(data.filter((e:any) => e.isPublic == true));
            }
        })
    }

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className={"kgo-bar-store"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""} defaultHref='/exchange'/>
                    </IonButtons>
                    <IonTitle>รายการของรางวัล</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <div style={{marginTop: 22, paddingLeft: 12, paddingRight: 12}}>
                        <IonRow>
                            <IonCol size={"12"}>
                                <div style={{textAlign: 'right'}}>
                                    คุณมี KGO สะสม
                                    <span style={{
                                        fontSize: 14,
                                        color: "#FFFFFF",
                                        background: "linear-gradient(#FF6E07, #FFA319)",
                                        borderRadius: 24,
                                        padding: 5,
                                        marginTop: 12,
                                        marginLeft: 5
                                    }}>
                                        <img src={dollar} className={'coin-dollar'}/>
                                        {(appContext.kgoReceiveBalance) ? numberWithCommas(appContext.kgoReceiveBalance.toFixed(2)) : '0'}&nbsp;
                                        KGO
                                    </span>
                                </div>
                            </IonCol>
                            <IonCol size={"12"}>
                                <IonCard className={"kgo-card"} style={{
                                    filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                                    background: "#B48C53"
                                }}>
                                    <IonCardContent style={{color: "#fff", paddingTop: 10, paddingBottom: 10}}>
                                        <IonRow style={{cursor: "pointer"}} className={'ion-no-padding'}
                                                onClick={() => history.push('/exchange/inventory')}>
                                            <IonCol size={"8"}>
                                                <h2 style={{fontSize: 24, marginTop: 24, fontWeight: 600}}>พวงกุญแจ
                                                    KGO</h2>
                                                <div style={{fontSize: 22, fontWeight: 300}}>
                                                    ด่วนน มีจำนวนจำกัด
                                                </div>
                                            </IonCol>
                                            <IonCol style={{textAlign: 'center',}} size={"4"}>
                                                <div style={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <img src={kgoNFT}
                                                         style={{width: '120px', verticalAlign: "middle"}}/>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{paddingTop: 0, marginTop: 10}}>
                            <IonCol size={"12"}>
                                <h4 style={{fontWeight: 700}}>รายการของรางวัล</h4>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size={"12"}>
                                <IonCard className={"kgo-card"} style={{
                                    marginTop: 0,
                                    background: '#FFFFFF',
                                    filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                                }}>
                                    <IonCardContent style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 3}}>
                                        {listNft.map((val: any, index) => (
                                            <IonRow key={index}>
                                                <IonCol size={'2'} sizeXs={'3'}>
                                                    <img src={val.image ? val.image : keyImg} style={{
                                                        marginTop: 10,
                                                        marginBottom: 10,
                                                        verticalAlign: "middle"
                                                    }}/>
                                                </IonCol>
                                                <IonCol size={"7"}>
                                                    <div className={"mission-text"} style={{color: 'black'}}>
                                                        <IonText className={"kgo-text-tran"}
                                                                 style={{display: "grid", marginTop: 15}}>
                                                            <IonText style={{fontSize: 16, fontWeight: 600}}>
                                                                {val?.name}
                                                            </IonText>
                                                            <IonText style={{fontSize: 12, color: "#848484"}}>
                                                                {val?.detail}
                                                            </IonText>
                                                            <IonText>
                                                                <img src={dollar} className={'coin-dollar'}
                                                                     style={{marginLeft: 0}}/>
                                                                <IonText
                                                                    style={{color: '#FF8A19'}}>{val?.price} </IonText> KGO
                                                            </IonText>
                                                        </IonText>
                                                    </div>
                                                </IonCol>
                                                <IonCol size={"2"} className={"ion-text-end"}>
                                                    <div>
                                                        <button className={'btn-end'} onClick={() => {
                                                            setShowAmount(true);
                                                            setSelectedTemplateId(val.id);
                                                            setSelectedTemplateText(val.name)
                                                            setPrice(val.price)
                                                        }
                                                        }>
                                                        แลก
                                                        </button>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                        ))}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />
                </div>
                <div style={{display: showAmount ? 'block' : 'none'}} className={'app-modal-blackdrop'}>
                    <div className={'app-modal-box'}>
                        <div className={'ion-text-center'}>
                            <div style={{fontSize: 20}}><b>ยืนยันการทำรายการ</b></div>
                            <div
                                style={{fontSize: 18}}>โปรดระบุจำนวน <strong>{selectedTemplateText}</strong> ที่คุณต้องการแลก
                            </div>
                        </div>
                        <IonRow>
                            <IonCol size={'6'}>
                                <IonItem style={{margin: '0 auto'}}>
                                    <IonInput className={'ion-text-end'} type={'number'} value={amount}
                                              placeholder="ระบุจำนวน"
                                              onIonChange={e => setAmount(parseInt(e.detail.value!, 10))}/>
                                </IonItem>
                            </IonCol>
                            <IonCol size={'6'} className={'ion-text-end'}>
                                <div style={{paddingTop: 16}}>
                                    ใช้ {numberWithCommas(amount * price)} KGO
                                </div>
                            </IonCol>
                            <IonCol size={'12'} className={'ion-text-center'}>
                                <IonText style={{fontWeight:600}}>
                                    เป็นเงิน {numberWithCommas(amount * 25)} บาท
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <div style={{textAlign: 'center', marginBottom: 6,marginTop:6}}>
                            คุณมี KGO สะสม
                            <span style={{
                                fontSize: 14,
                                color: "#FFFFFF",
                                background: "linear-gradient(#FF6E07, #FFA319)",
                                borderRadius: 24,
                                padding: 5,
                                marginTop: 12,
                                marginLeft: 5
                            }}>
                                        <img src={dollar} className={'coin-dollar'}/>
                                {(appContext.kgoReceiveBalance) ? numberWithCommas(appContext.kgoReceiveBalance.toFixed(2)) : '0'}&nbsp;
                                KGO
                                    </span>
                        </div>

                        <div className={'ion-text-center'}>
                            <IonItem lines="none">
                                <IonCheckbox checked={claimNow} onIonChange={e => setClaimNow(e.detail.checked)}/>
                                <IonLabel style={{marginLeft: 12, color: 'gray'}}> ทำการส่งแลกสินค้าทันที </IonLabel>
                            </IonItem>
                            <IonButton onClick={() => {
                                setShowAmount(false)
                            }} style={{marginTop: 12, marginRight: 22, minWidth: 110}} color="medium">ยกเลิก</IonButton>
                            <IonButton onClick={async () => {
                                setShowLoading(true);
                                await buyNft(amount, claimNow);
                            }} style={{minWidth: 110}} color="warning">ยืนยัน</IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Redemption;

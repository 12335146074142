import {
    IonBackButton, IonButton,
    IonButtons, IonCard, IonCardContent, IonCheckbox,
    IonCol,
    IonContent,
    IonHeader, IonLabel, IonLoading,
    IonPage,
    IonRow,
    IonTitle, IonText,
    IonToolbar, useIonAlert, IonImg, IonFooter, IonAlert
} from '@ionic/react';
import React, {useContext, useEffect, useRef, useState} from "react";
import {arrowBackOutline} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import {AppContext} from "../context/state";
import {nftModel} from "../models/Data";
import kgoNFT from "../assets/kgo-nft.png";
import keyImg from "../assets/key.png";
import dollar from "../assets/coin_kgo1.svg";
import dollar2 from "../assets/dollar.png";

const Inventory: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [confirmClaim, setConfirmClaim] = useState(false);
    const [confirmCancelClaim, setConfirmCancelClaim] = useState(false);
    const [claimAmount, setClaimAmount] = useState(1);
    const [maxCancelClaim, setMaxCancelClaim] = useState(0);
    const [templateIDSelected, setTemplateIDSelected] = useState(0);
    const [maxClaim, setMaxClaim] = useState(1);
    const [disableButton, setDisableButton] = useState(false);

    const getStoreProfile = () => {
        console.log('getStoreProfile');
        if (appContext.apiService) {
            appContext.getProfile();
        } else {
            console.error('apiService not init!');
        }
    }

    useEffect(() => {
        console.log('inventory', appContext.inventory)
        if(appContext.inventory.nftVouchers != null)
        console.log('inventory', Object.keys(appContext.inventory.nftVouchers))
        if (!appContext.profile.businessName) {
            getStoreProfile();
        }
    }, []);

   useEffect(() => {
        console.log('inventory', 'change')
    }, [appContext.inventory]);

    const claimNft = async (amountOfItem: number) =>{
        appContext.apiService.claimNft(templateIDSelected, amountOfItem).then((res) =>{
            if(res.success){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'ทำรายการสำเร็จ',
                    buttons: ['Ok',],
                }).then()

                getStoreProfile();
            }
        })
    }

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className={"kgo-bar-store"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""} defaultHref='/exchange'/>
                    </IonButtons>
                    <IonTitle>รายการของฉัน</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <div style={{marginTop: 22, paddingLeft: 12, paddingRight:12}}>
                        <IonRow>
                            <IonCol size={"12"}>
                                <div style={{textAlign:'right'}}>
                                    คุณมี KGO สะสม
                                    <span  style={{fontSize:14,color:"#FFFFFF",background: "linear-gradient(#FF6E07, #FFA319)" , borderRadius: 24, padding:5,marginTop:12,marginLeft:5}} >
                                        <img src={dollar} className={'coin-dollar'} />
                                        {(appContext.kgoReceiveBalance) ? numberWithCommas(appContext.kgoReceiveBalance.toFixed(2)) : '0'}&nbsp;
                                        KGO
                                    </span>
                                </div>
                            </IonCol>
                            <IonCol size={"12"}>
                                <IonCard className={"kgo-card"} style={{filter:"drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",background:"#FFA412"}}>
                                    <IonCardContent style={{color:"#fff",paddingTop:0,paddingBottom:0}} >
                                        <IonRow style={{cursor: "pointer"}} className={'ion-no-padding'} onClick={() => history.push('/exchange/inventory')}>
                                            <IonCol size={"8"}>
                                                <h2  style={{fontSize: 24,marginTop:24,fontWeight:600}}>คลัง NFT ของฉัน</h2>
                                                <div style={{fontSize: 12,fontWeight:300}}>
                                                    คุณสามารถกดเคลมรางวัลNFTที่แลกไว้ได้เลยทันที
                                                </div>
                                            </IonCol>
                                            <IonCol style={{textAlign: 'center',}} size={"4"}>
                                                <div style={{height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <img src={kgoNFT} style={{width:'120px',verticalAlign:"middle"}}  />
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{paddingTop: 0, marginTop: 10}}>
                            <IonCol size={"12"}>
                                <IonText style={{fontWeight:700,fontSize:16}}>เลือกรายการเพื่อ Claim ของรางวัล</IonText>
                            </IonCol>
                        </IonRow>
                    </div>
                    <div style={{overflowY: "scroll", height: "calc(100vh - 200px)", padding:15}}>
                        {
                            (appContext.inventory.nftVouchers && appContext.inventory.nftVouchers.length > 0) ? Object.keys(appContext.inventory.nftVouchers).map((r, i: number) => {
                                const itemIndex = parseInt(r)
                                const itemAmount = appContext.inventory.nftVouchers[itemIndex]
                                const itemClaimAmount = appContext.inventory.nftClaimVouchers ? appContext.inventory.nftClaimVouchers[itemIndex] : 0
                                if(nftModel[itemIndex] == null){
                                    return ;
                                }
                                return (
                                <IonRow key={i}>
                                    <IonCol size={"12"} >
                                        <IonCard className={"kgo-card"} style={{marginTop:0,background:'#FFFFFF',filter:"drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",}}>
                                            <IonCardContent style={{padding:0}}>
                                                <IonRow>
                                                    <IonCol size={'2'} sizeXs={'3'} >
                                                        <img src={nftModel[itemIndex]?.img == 'key' ? keyImg : dollar2 } style={{
                                                            marginTop:10,marginBottom:10,verticalAlign:"middle",
                                                            maxWidth: 80
                                                        }} />
                                                    </IonCol>
                                                    <IonCol size={"6"}>
                                                        <div className={"mission-text"} style={{color:'black'}}>
                                                            <IonText className={"kgo-text-tran"} style={{display: "grid",marginTop: 10}}>
                                                                <div style={{fontSize:16,fontWeight:600}}>
                                                                    {nftModel[itemIndex]?.nftName}
                                                                </div>
                                                                <div style={{fontSize:18,fontWeight:600}}>
                                                                    จำนวน {itemAmount - itemClaimAmount} รายการ
                                                                </div>
                                                                <div style={{color: '#b50b0b',fontSize:18,fontWeight:600}}>
                                                                    รอเคลม {itemClaimAmount }  รายการ
                                                                </div>
                                                            </IonText>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size={"3"}   style={{marginTop:10,textAlign:'center'}}>
                                                        <IonButton disabled={disableButton || (itemAmount - itemClaimAmount) <= 0} expand="block" className={"kgo-btn-store"} style={{marginBottom:8}} onClick={(e) => {
                                                            setTemplateIDSelected(i);
                                                            // const amount = itemClaimAmount ? itemAmount - itemClaimAmount : appContext.inventory.nftVouchers[r]
                                                            setClaimAmount(1);
                                                            setMaxClaim(itemAmount - itemClaimAmount);
                                                            setConfirmClaim(true);
                                                            setDisableButton(true);
                                                        }} >Claim
                                                        </IonButton>

                                                        <IonButton disabled={disableButton  || (itemClaimAmount) <= 0} expand="block" className={"kgo-btn-store-cancel"} style={{marginBottom:8}} onClick={(e) => {
                                                            setTemplateIDSelected(i);
                                                            // const amount = itemClaimAmount ? itemAmount - itemClaimAmount : appContext.inventory.nftVouchers[r]
                                                            setMaxCancelClaim(itemClaimAmount);
                                                            setConfirmCancelClaim(true);
                                                            setDisableButton(true);

                                                        }} >ยกเลิก
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            )
                            }) : (
                                <h1 className='ion-text-center' style={{marginTop: 42}}>ไม่พบข้อมูล...</h1>
                            )
                        }
                    </div>
                    <IonAlert
                        isOpen={confirmClaim}
                        onDidDismiss={() => setConfirmClaim(false)}
                        cssClass='my-custom-class'
                        // header={'ระบุจำนวนที่ต้องการเคลม'}
                        message={'<strong>ระบุจำนวนที่ต้องการเคลม</strong>'}
                        inputs={[
                            {
                                name: 'amount',
                                id: 'amount',
                                type: 'number',
                                placeholder: 'จำนวนที่ต้องการเคลม',
                                value: claimAmount,
                                min: 1,
                                max: maxClaim
                            },
                        ]}
                        buttons={[
                            {
                                text: 'ปิด',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                    console.log('Confirm Cancel');
                                    setTimeout( ()=> {
                                        setDisableButton(false)
                                    }, 2000)
                                }
                            },
                            {
                                text: 'เคลม',
                                handler: async (data) => {
                                    setTimeout( ()=> {
                                        setDisableButton(false)
                                    }, 2000)
                                    if(data.amount > maxClaim){
                                        alert('จำนวนต้องไม่เกิน ' + maxClaim)
                                    }else{
                                        if(data?.amount){
                                            await claimNft( parseInt(data.amount))
                                        }
                                    }

                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={confirmCancelClaim}
                        onDidDismiss={() => setConfirmCancelClaim(false)}
                        cssClass='my-custom-class'
                        // header={'ระบุจำนวนที่ต้องการเคลม'}
                        message={'<strong>ระบุจำนวนที่ต้องการยกเลิกเคลม</strong>'}
                        inputs={[
                            {
                                name: 'amount',
                                id: 'amount',
                                type: 'number',
                                placeholder: 'จำนวนที่ต้องการยกเลิก',
                                min: 0,
                                max: maxCancelClaim
                            },
                        ]}
                        buttons={[
                            {
                                text: 'ปิด',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                    console.log('Confirm Cancel');
                                    setTimeout( ()=> {
                                        setDisableButton(false)
                                    }, 2000)
                                }
                            },
                            {
                                text: 'ยกลิกเคลม',
                                handler: async (data) => {
                                    setTimeout( ()=> {
                                        setDisableButton(false)
                                    }, 2000)
                                    console.log('Confirm Ok', data);
                                    if(data.amount > maxCancelClaim){
                                        alert('รายการยกเลิกต้องไม่เกิน ' + maxCancelClaim)
                                    }else{
                                        if(data?.amount){
                                            await claimNft( - parseInt(data.amount))
                                        }
                                    }



                                }
                            }
                        ]}
                    />

                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />
                </div>
            </IonContent>
            {/*<IonFooter>*/}
            {/*    <IonRow className={"kgo-row"} style={{marginTop:5}}>*/}
            {/*        <IonCol size={"12"}>*/}
            {/*            <IonButton expand="block" className={"kgo-btn-store"} style={{marginBottom:8}} onClick={(e) => {*/}
            {/*                claimNft().then();*/}
            {/*            }} >Claim*/}
            {/*            </IonButton>*/}
            {/*        </IonCol>*/}
            {/*    </IonRow>*/}
            {/*</IonFooter>*/}
        </IonPage>
    );
};

export default Inventory;

import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonCard,
    IonCardContent,
    IonRow,
    IonIcon, IonBackButton
} from '@ionic/react';
import React from "react";
import {
    arrowBackOutline,
    logoFacebook,
    logoTwitter,
} from "ionicons/icons";
import IconLine from "../assets/line-icon.png";
import {RouteComponentProps} from "react-router";
import {isPlatform} from "@ionic/react";

const KgoInfo: React.FC<RouteComponentProps> = ({history}) => {
    function goToFacebook() {
        if(isPlatform('ios')){
            window.open('fb://profile?id=105196832059252')
        }else if(isPlatform('android')){
            window.open('fb://page/105196832059252')
        }else {
            window.open('https://www.facebook.com/KGOtoken', '_blank');
        }
    }
    function goToTwitter() {
        window.open('https://twitter.com/MetaverseSiam', '_blank');
    }
    function goToLine(){
        window.open('https://line.me/R/ti/p/@920qmmzo', '_blank');
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonButtons slot="start">
                            <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                           defaultHref='/main'/>
                        </IonButtons>
                        <IonTitle>ช่องทางการติดต่อ</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-light"} >
                                <IonCardContent>
                                    <IonRow className="ion-text-center">
                                        <IonCol size={"12"}>
                                            <img src={process.env.PUBLIC_URL + '/assets/icon/KGO-Logo.png'} style={{height:263,marginTop:20}} />
                                        </IonCol>
                                        <IonCol size={"12"} style={{marginTop:25}}>
                                            version 1.0.0
                                        </IonCol>

                                        <IonCol size={"12"} style={{marginTop:25}}>
                                            <IonButton className={"kgo-btn"} expand={"block"} onClick={(e: any)=>{ goToFacebook() }}>
                                                    <span className="ion-text-left">
                                                        <IonIcon style={{fontSize:16}} icon={logoFacebook} /> Facebook
                                                    </span>
                                            </IonButton>

                                            <IonButton className={"kgo-btn"} expand={"block"} onClick={(e: any)=>{ goToTwitter() }}>
                                                    <span className="ion-text-left">
                                                        <IonIcon style={{fontSize:16}} icon={logoTwitter} /> Twitter
                                                    </span>
                                            </IonButton>
                                            <IonButton className={"kgo-btn"} expand={"block"} onClick={(e: any)=>{goToLine()  }}>
                                                    <span className="ion-text-left">
                                                        <img src={IconLine} style={{height:16}}/> Line Official
                                                    </span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default KgoInfo;

import {
    IonAlert,
    IonBackButton,
    IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonHeader, IonIcon, IonLoading,
    IonPage,
    IonRow, IonText,
    IonTitle,
    IonToolbar, useIonAlert
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {arrowBackOutline, cart} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import {AppContext} from "../context/state";

import nft from "../assets/nft.png";
import bag from "../assets/bag.png";
import TransactionList from "../components/TransactionList";
import dollar from "../assets/coin_kgo1.svg";
import titleImg from "../assets/5409459.png";
import kgoNFT from "../assets/kgo-nft.png";
import redemption from "../assets/5378862.png";
const Exchange: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [showLoading, setShowLoading] = useState(false);

    const getStoreProfile = () => {
        console.log('getStoreProfile');
        if (appContext.apiService) {
            appContext.getProfile();
        } else {
            console.error('apiService not init!');
        }
    }

    useEffect(() => {
        getStoreProfile();
    }, [appContext.notify]);

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar  className={"kgo-bar-store"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""} defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>แลกของรางวัล</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <div style={{marginTop: 22, paddingLeft: 12, paddingRight:12 }}>
                        <IonRow className={"ion-text-start"}>
                            <IonCol size={"12"}>
                                <div style={{textAlign:'right'}}>
                                    คุณมี KGO สะสม
                                    <span  style={{fontSize:14,color:"#FFFFFF",background: "linear-gradient(#FF6E07, #FFA319)" , borderRadius: 24, padding:'3px 7px 3px 7px',marginTop:12,marginLeft:5}} >
                                        <img src={dollar} className={'coin-dollar'} />
                                        {(appContext.kgoReceiveBalance) ? numberWithCommas(appContext.kgoReceiveBalance.toFixed(2)) : '0'}&nbsp;
                                        KGO
                                    </span>
                                </div>
                                <h5 style={{paddingTop: 0, marginTop: 25,textAlign:'center',fontWeight:700}}>นำเหรียญ KGO ของคุณมาแลกของรางวัล</h5>
                            </IonCol>
                        </IonRow>
                    </div>
                    <div style={{paddingLeft: 12, paddingRight:12,textAlign:'center'}}>
                        <img src={titleImg} />
                    </div>

                    <div style={{marginTop: 22, paddingLeft: 12, paddingRight:12}}>
                        <IonRow>
                            <IonCol size={"12"}>
                                <h4 style={{paddingTop: 0, marginTop: 10,fontWeight:700}}>บริการต่างๆ</h4>

                                <IonCard className={"kgo-card"} style={{filter:"drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",background:"#FFA412"}}>
                                    <IonCardContent style={{color:"#fff",paddingTop:0,paddingBottom:0}} >
                                        <IonRow style={{cursor: "pointer"}} className={'ion-no-padding'} onClick={() => history.push('/exchange/redemption')}>
                                            <IonCol size={"8"}>
                                                <h2  style={{fontSize: 24,marginTop:24,fontWeight:600}}>แลกของรางวัล</h2>
                                                <div style={{fontSize: 12,fontWeight:300}}>
                                                    นำเหรียญ KGO ของคุณมาแลกของรางวัลมากมาย
                                                </div>
                                            </IonCol>
                                            <IonCol style={{textAlign: 'center',}} size={"4"}>
                                                <div style={{height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <img src={kgoNFT} style={{width:'120px',verticalAlign:"middle"}}  />
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard className={"kgo-card"} style={{filter:"drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",background:"#668FE1"}}>
                                    <IonCardContent style={{color:"#fff",paddingTop:0}} >
                                        <IonRow style={{cursor: "pointer"}} className={'ion-no-padding'} onClick={() => history.push('/exchange/inventory')}>
                                            <IonCol size={"8"}>
                                                <h2  style={{fontSize: 24,marginTop:24,fontWeight:600}}>คลัง NFT ของฉัน</h2>
                                                <div style={{fontSize: 12,fontWeight:300}}>
                                                    คุณสามารถกดเคลมรางวัลNFTที่แลกไว้ได้เลยทันที
                                                </div>
                                            </IonCol>
                                            <IonCol style={{textAlign: 'center',padding:0}} size={"4"}>
                                                <div style={{height:'100%',display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                                                    <img src={redemption} style={{width:'120px',verticalAlign:"middle",marginTop:'15%'}}  />
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>



                            </IonCol>
                        </IonRow>
                    </div>

                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />

                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <h3 style={{marginTop: 42}}>รายการกระเป๋าแต้มสะสม</h3>
                            <IonCard style={{marginBottom: 20}} className={"kgo-card kgo-card-tran-store"}>
                                <IonCardContent>
                                    {appContext.address && (
                                        <TransactionList walletAddress={appContext.pointAddress} mode={'component'}/>)}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </div>


            </IonContent>
        </IonPage>
    );
};

export default Exchange;

import {
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonToolbar,
    IonIcon,
    IonCard,
    IonCardContent,
    IonFooter,
    IonText, IonLabel, IonInput, IonItem, IonButton, IonRouterLink, IonImg
} from '@ionic/react';
import {
    person, addCircle, search, arrowUpCircle, pencil, arrowDownCircle, cart, arrowDown, arrowUp
} from 'ionicons/icons';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {VoucherModel} from "../models/Data";

import kgoLogoWhite from "../assets/kgo-white.svg";
import storeWallet from "../assets/store-wallet.svg";
import storeVoucher from "../assets/store-voucher-icon-active.svg";
import dollar from "../assets/coin_kgo1.svg";
import Rectangle from "../assets/Rectangle-78.png";
import LogoKgo from "../assets/kgo-nft.png";

const VoucherMain: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [voucherList, setVoucherList] = useState<Array<VoucherModel>>([])

    const getStoreProfile = () => {
        console.log('getStoreProfile');
        if (appContext.apiService) {
            appContext.getProfile();
        } else {
            console.error('apiService not init!');
        }
    }

    useEffect(() => {
        getStoreProfile();
    }, [appContext.notify]);

    useEffect(() => {
        if(appContext.vouchers){
            console.log('voucher',appContext.vouchers);
            setVoucherList(appContext.vouchers);
        }
    }, [appContext.vouchers]);

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonButtons slot="start" style={{fontSize:20,marginLeft:10}}>
                            <IonText>KGO Business </IonText>
                        </IonButtons>
                        <IonButtons slot="end" onClick={(e: any) => {
                            history.push('/profile');
                        }} style={{marginRight:10, fontSize:20, cursor: "pointer"}}><IonIcon icon={person}/>
                        </IonButtons>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-img"}>
                                <IonCardContent>
                                    <IonRow style={{display: 'flex', alignItems:'center'}}>
                                        <img src={kgoLogoWhite} style={{width: 16.8,marginRight:5}}/>
                                        <IonText style={{fontSize:14, color:"#FFFFFF"}}> {appContext.profile.businessName ? appContext.profile.businessName : appContext.profile.businessPhoneNumber}</IonText>

                                        <IonText style={{fontSize: 12, marginLeft: 'auto'}}>
                                            <div style={{background: "rgba(255, 255, 255, 0.42)", borderRadius: 24, paddingLeft:10,paddingRight:10}}>
                                                <div style={{color: "#414143"}}>{(appContext.address) ? appContext.address.substr(0, 4)+'...'+appContext.address.substr(-4) : ''}</div>
                                            </div>
                                        </IonText>
                                    </IonRow>
                                    <IonRow className="ion-text-center" style={{margin:"18px 0 20px"}}>
                                        <IonCol size='12'>
                                            <IonText color={"light"} style={{fontSize:14,marginRight:8}}>KGO ของร้าน </IonText>
                                            <IonText color={"light"} style={{fontSize:36, fontWeight:600}}>
                                                {(appContext.kgoBalance) ? numberWithCommas(appContext.kgoBalance.toFixed(2)) : '0'} <IonText style={{fontSize:16, fontWeight:400, verticalAlign:"middle"}} color={"light"}>
                                                <img src={dollar} style={{width: 24,verticalAlign:"sub"}}/>
                                            </IonText>
                                            </IonText>
                                        </IonCol>
                                        <IonCol size='12' style={{borderTop:"1px solid lightgray"}}>
                                            <IonText color={"light"} style={{fontSize:14,marginRight:8}}>KGO สะสม </IonText>
                                            <IonText color={"light"} style={{fontSize:24, fontWeight:600}}>
                                                {(appContext.kgoReceiveBalance) ? numberWithCommas(appContext.kgoReceiveBalance.toFixed(2)) : '0'} <IonText style={{fontSize:16, fontWeight:400, verticalAlign:"middle"}} color={"light"}>
                                                <img src={dollar} className={'coin-dollar'} />
                                            </IonText>
                                            </IonText>
                                            <button style={{fontSize:10,color:"#FFFFFF",background: "#625D5A", borderRadius: 24, padding:8,marginTop:5,float:'right'}}  onClick={() => history.push('/exchange')}>
                                                แลกของรางวัล
                                            </button>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol size={"12"}>
                                            <IonButton className={"kgo-btn kgo-light"} expand={"block"} routerLink={"/voucher/create"} style={{color:"#000000",fontSize:14,fontWeight:600}}>
                                                สร้าง Voucher <IonIcon size={"small"} slot="end" icon={addCircle}/>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className={"kgo-row"}>
                        <IonCol size='12' className={"ion-text-center"} style={{margin:"14px 0"}}>
                            <IonText style={{fontSize: 18,fontWeight:600}}>
                                รายการ Voucher
                            </IonText>
                        </IonCol>
                        <IonCol size='12'>
                            <IonItem className={"kgo-input-light form"}>
                                <IonInput inputmode="text" style={{fontSize:16}}
                                          placeholder="ค้นหา"
                                          onIonChange={e => {
                                              if(e.detail.value){
                                                  const temp = appContext.vouchers.filter(r => r.name.indexOf(e.detail.value!) > -1);
                                                  console.log('e.detail.value', e.detail.value, temp)
                                                  setVoucherList(temp);
                                              }else{
                                                  setVoucherList(appContext.vouchers)
                                              }
                                          }}/>
                                <IonIcon size={"small"} slot="end" icon={search}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <div className={"kgo-row voucher-list"}>
                        {voucherList.filter(e => e.isDeleted == false).length == 0 ? (
                                <h4 className='ion-text-center'><IonLabel>ยังไม่มีรายการ Voucher...</IonLabel></h4>
                            )
                            : voucherList.filter(e => e.isDeleted == false).map((r: any, i: number) => (
                                <div key={i} className={"kgo-row-tran"}  onClick={(e: any) => history.push('/voucher/gen/'+r.id)}
                                     style={{width: '100%',display:'flex',border: "1pt solid rgba(0, 0, 0, 0.1)",borderRadius: 12,filter:"drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))"}}>
                                    <div style={{width:20, minHeight:'100%', borderRadius: "12px 0 0 12px",display:'inline-block',marginRight:5}} className={r.type == 'send' ? "send-color" : "receive-color"} />
                                    <div style={{display:'inline-block'}}>
                                        <img src={r.image && r.image !='' ? r.image: LogoKgo} style={{height:80,minWidth:90,marginTop:12,marginBottom:5,objectFit:'cover',borderRadius: 12}} />
                                    </div>
                                    <div style={{display:'flex',width:'100%',flexGrow:3,marginLeft:5,marginTop:5,flexDirection:'column'}}>
                                        <div>
                                            <IonText style={{fontWeight:500,fontSize:20}}>
                                                {r.name}
                                            </IonText>
                                            {r.isPublic ? (
                                                <IonText style={{float:'right'}}>
                                                <span style={{background: "#FFA412",fontSize: 9, borderRadius: 24, paddingLeft:10,paddingRight:10,marginRight:5}}>
                                                    <span style={{color: "#414143"}}>Public</span>
                                                </span>
                                                </IonText>
                                            ) : ''}
                                        </div>
                                        <div style={{marginTop:'auto',marginBottom:8}}>
                                            <IonText style={{fontSize: 12,fontWeight: 400, marginRight:10}}>
                                                {r.type == 'send' ? (
                                                    <span style={{background: "#45B865",fontSize: 13, borderRadius: 12,paddingTop:5,paddingBottom:5, paddingLeft:5,paddingRight:5,float:'right',marginRight:5}}>
                                                    <IonIcon style={{color:"#FFFFFF",verticalAlign: "middle",fontSize:18}} icon={arrowDown}/>
                                                    <span style={{color: "#FFFFFF"}}> ร้านส่งให้</span>
                                                </span>
                                                ): (
                                                    <span style={{background: "#F85555",fontSize: 13, borderRadius: 12,paddingTop:5,paddingBottom:5, paddingLeft:5,paddingRight:5,float:'right',marginRight:5}}>
                                                    <IonIcon style={{color:"#FFFFFF",verticalAlign: "middle",fontSize:18}} icon={arrowUp}/>
                                                    <span style={{color: "#FFFFFF"}}> ลูกค้าใช้&nbsp;</span>
                                                </span>
                                                )}
                                                <img src={dollar} style={{width: 16,verticalAlign:"sub"}}/> <IonText style={{fontSize:20,fontWeight:500,color: "#FF8A19"}}>{numberWithCommas(r.amount)} <IonText style={{fontSize:12, color: "#242424"}}>KGO</IonText></IonText>
                                            </IonText>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </IonContent>
            <IonFooter style={{backgroundColor:'#242424',height: 65}}>
                <div className="app-container" >
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonRow className='ion-text-center'>
                            <IonCol size={"6"} >
                                <IonText>
                                    <img src={storeVoucher} style={{width: 30}}/>
                                    <div  style={{fontSize:12}}>Voucher</div>
                                </IonText>
                            </IonCol>
                            <IonCol size={"6"}>
                                <IonText onClick={() => history.push('/home')}>
                                    <img src={storeWallet} style={{width: 30}}/>
                                    <div style={{fontSize:12}}>Transaction</div>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonToolbar>
                </div>
            </IonFooter>
        </IonPage>
    );
};

export default VoucherMain;

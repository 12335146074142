import {
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonToolbar,
    IonIcon,
    IonCard,
    IonCardContent,
    IonFooter,IonText
} from '@ionic/react';
import {
    person
} from 'ionicons/icons';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import TransactionList from "../components/TransactionList";

import kgoLogoWhite from "../assets/kgo-white.svg";
import storeWallet from "../assets/store-wallet-active.svg";
import storeVoucher from "../assets/store-voucher-icon.svg";
import {profileModel} from "../models/Data";

const Store: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    useEffect(() => {
        getProfile();
    }, [appContext.notify]);

    function getProfile() {
        console.log('getBalance');
        if (appContext.apiService) {
            appContext.getProfile();
        } else {
            console.log('false');
        }
    }

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonButtons slot="start" style={{fontSize:20,marginLeft:10}}>
                            <IonText>KGO Business </IonText>
                        </IonButtons>
                        <IonButtons slot="end" onClick={(e: any) => {
                            history.push('/profile');
                        }} style={{marginRight:10, fontSize:20}}><IonIcon icon={person}/>
                        </IonButtons>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-img"}>
                                <IonCardContent>
                                    <IonRow style={{display: 'flex', alignItems:'center'}}>
                                        <img src={kgoLogoWhite} style={{width: 16.8,marginRight:5}}/>
                                        <IonText style={{fontSize:14, color:"#FFFFFF"}}> {appContext.profile?.businessName ? appContext.profile?.businessName : appContext.profile?.businessPhoneNumber} </IonText>

                                        <IonText style={{fontSize: 12, marginLeft: 'auto'}}>
                                            <div style={{background: "rgba(255, 255, 255, 0.42)", borderRadius: 24, paddingLeft:10,paddingRight:10}}>
                                                <div style={{color: "#414143"}}>{(appContext?.address) ? appContext?.address.substr(0, 4)+'...'+appContext?.address.substr(-4) : ''}</div>
                                            </div>
                                        </IonText>
                                    </IonRow>

                                    <IonRow className="ion-text-center" style={{margin:"24px 0 20px"}}>
                                        <IonCol size='12'>
                                            <IonText color={"light"} style={{fontSize:36, fontWeight:600}}>{appContext.kgoBalance > 0 ? numberWithCommas(appContext.kgoBalance.toFixed(2)) : '0'} <IonText style={{fontSize:16, fontWeight:400, verticalAlign:"middle"}} color={"light"}>KGO</IonText>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-tran-store"}>
                                <IonCardContent>
                                    {appContext.address && (<TransactionList walletAddress={appContext.address} subWalletAddress={appContext.pointAddress} mode={'component'}/>)}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
            <IonFooter style={{backgroundColor:'#242424',height: 65}}>
                <div className="app-container" >
                    <IonToolbar className={"kgo-bar-store"}>
                        <IonRow className='ion-text-center'>
                            <IonCol size={"6"}>
                                <IonText onClick={() => history.push('/main')}>
                                    <img src={storeVoucher} style={{width: 30}}/>
                                    <div  style={{fontSize:12}}>Voucher</div>
                                </IonText>
                            </IonCol>
                            <IonCol size={"6"}>
                                <IonText>
                                    <img src={storeWallet} style={{width: 30}}/>
                                    <div style={{fontSize:12}}>Transaction</div>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonToolbar>
                </div>
            </IonFooter>
        </IonPage>
    );
};

export default Store;
